import React, { ChangeEvent, useRef, useState } from "react";
import Input from "../../common/Input/Input";
import {
  ACTIVITY_INPUTS,
  INPUTS,
  VAT_INPUTS,
  WidgetInput,
} from "../../constants/widgetInputs";
import {
  getFormattedMessage,
  getLabelText,
  getTotalPrice,
} from "../../utils/widget/widgetHelper";
import SuccessMark from "../../asset/successmark.svg";
import ExclamationMark from "../../asset/exclamationmark.svg";
import WarningSign from "../../asset/warning-sign.svg";
import styles from "./CalculatorWidget.module.scss";
import { RECAPTCHA_KEY } from "../../constants/emailJS";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingSpinner from "src/common/LoadingSpinner/LoadingSpinner";

const CalculcatorWidget = (): JSX.Element => {
  const [inputData, setInputData] = useState<WidgetInput[]>(INPUTS);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [vatInputs, setVatInputs] = useState<WidgetInput[]>(VAT_INPUTS);
  const [activityInputs, setActivityInputs] =
    useState<WidgetInput[]>(ACTIVITY_INPUTS);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [isFormEmpty, setIsFormEmpty] = useState(false);

  const allInputs = [...activityInputs, ...inputData, ...vatInputs];

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const onIncrement = (label: string) => {
    if (isSuccess) {
      setIsSuccess(false);
    }

    setInputData((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.label === label
          ? {
              ...prevInput,
              value:
                98 >= prevInput.value ? prevInput.value + 1 : prevInput.value,
            }
          : { ...prevInput }
      );

      return newInputs;
    });
  };

  const onDecrement = (label: string) => {
    if (isSuccess) {
      setIsSuccess(false);
    }

    setInputData((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.label === label
          ? {
              ...prevInput,
              value:
                0 >= prevInput.value ? prevInput.value : prevInput.value - 1,
            }
          : { ...prevInput }
      );

      return newInputs;
    });
  };

  const onActivityChecboxChange = (label: string) => {
    if (isSuccess) {
      setIsSuccess(false);
    }

    setActivityInputs((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.label === label
          ? {
              ...prevInput,
              value: 1,
            }
          : prevInput.type === "checkbox"
          ? {
              ...prevInput,
              value: 0,
            }
          : {
              ...prevInput,
            }
      );

      return newInputs;
    });
  };

  const onVatChecboxChange = (label: string) => {
    if (isSuccess) {
      setIsSuccess(false);
    }

    setVatInputs((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.label === label
          ? {
              ...prevInput,
              value: 1,
            }
          : prevInput.type === "checkbox"
          ? {
              ...prevInput,
              value: 0,
            }
          : {
              ...prevInput,
            }
      );

      return newInputs;
    });
  };

  const onCheckboxChange = (label: string) => {
    if (isSuccess) {
      setIsSuccess(false);
    }

    setInputData((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.label === label
          ? {
              ...prevInput,
              value: 1,
            }
          : prevInput.type === "checkbox"
          ? {
              ...prevInput,
              value: 0,
            }
          : {
              ...prevInput,
            }
      );

      return newInputs;
    });
  };

  const getValue = (value: number): number | null => {
    const num = Number(value);

    if (isNaN(num) || num < 0) {
      return 0;
    }

    if (num > 999) {
      return null;
    }

    return num;
  };

  const onNumberChange = (event: ChangeEvent<any>, label: string) => {
    const { value } = event.target;

    if (isSuccess) {
      setIsSuccess(false);
    }

    setInputData((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.label === label
          ? {
              ...prevInput,
              value: getValue(value) ?? prevInput.value,
            }
          : { ...prevInput }
      );

      return newInputs;
    });
  };

  const resetForm = () => {
    setInputData(INPUTS);
    setEmail("");
    setPhone("");
    setVatInputs(VAT_INPUTS);
    setActivityInputs(ACTIVITY_INPUTS);
    setIsLoading(false);
    setIsFormEmpty(false);
    setRecaptchaValue(null);
  };

  const handleWpSmtpSubmit = async () => {
    const baseUrl = window.location.origin;

    const formData = {
      email: email,
      phone: phone,
      message: getFormattedMessage(allInputs),
      totalPrice: getTotalPrice(allInputs),
    };

    setIsSuccess(false);
    setIsLoading(true);

    try {
      const response = await fetch(
        `${baseUrl}/wp-json/contact-form/v1/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setIsSuccess(true);
        resetForm();
      } else {
        setIsError(true);
        resetForm();
      }
    } catch (error) {
      setIsError(true);
      resetForm();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!getFormattedMessage(allInputs)) {
      setIsFormEmpty(true);
      return;
    }

    if (recaptchaValue) {
      // sendEmail({
      //   email: email,
      //   phone: phone,
      //   message: getFormattedMessage(allInputs),
      //   totalPrice: getTotalPrice(allInputs),
      // });

      handleWpSmtpSubmit();
    }

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleRecaptchaChange = (value: string | null) => {
    if (value) {
      setRecaptchaValue(value);
    }
  };

  return (
    <div className={`${styles.widgetContainer} ababa-widget-container`}>
      <div className={styles.widgetRightColumn}>
        <div className={styles.checkboxSection}>
          <h4>Pasirinkite veiklą</h4>
          <div className={styles.checkboxSectionBox}>
            {activityInputs
              .filter((input) => input.type === "checkbox")
              .map((input, i) => {
                return (
                  <Input
                    key={i}
                    type={input.type}
                    label={input.label}
                    pricePerUnit={input.pricePerUnit.toFixed(2)}
                    value={input.value}
                    onCheckboxChange={onActivityChecboxChange}
                  />
                );
              })}
          </div>
        </div>
        {inputData
          .filter((input) => input.type === "text")
          .map((input, i) => {
            return (
              <Input
                key={i}
                type={input.type}
                label={input.label}
                pricePerUnit={input.pricePerUnit.toFixed(2)}
                value={input.value}
                helperText={input?.helperText}
                onIncrement={onIncrement}
                onDecrement={onDecrement}
                onNumberChange={onNumberChange}
              />
            );
          })}
        <div className={styles.checkboxSection}>
          <h4>Ar esate/ planuojate būti PVM mokėtoju?</h4>
          <div className={styles.checkboxSectionBox}>
            {vatInputs
              .filter((input) => input.type === "checkbox")
              .map((input, i) => {
                return (
                  <Input
                    key={i}
                    type={input.type}
                    label={input.label}
                    pricePerUnit={input.pricePerUnit.toFixed(2)}
                    value={input.value}
                    onCheckboxChange={onVatChecboxChange}
                  />
                );
              })}
          </div>
        </div>
        <div className={styles.checkboxSection}>
          <h4>Ar reikalingos periodinės ataskaitos</h4>
          <div className={styles.checkboxSectionBox}>
            {inputData
              .filter((input) => input.type === "checkbox")
              .map((input, i) => {
                return (
                  <Input
                    key={i}
                    type={input.type}
                    label={input.label}
                    pricePerUnit={input.pricePerUnit.toFixed(2)}
                    value={input.value}
                    onCheckboxChange={onCheckboxChange}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <div className={styles.widgetLeftColumn}>
        {allInputs.map((input) => {
          if (input.value >= 0.1 && input.pricePerUnit > 0.1) {
            return (
              <>
                <div className={styles.widgetSingleItem}>
                  <p className={styles.singleItemLabel}>
                    {getLabelText(input.label, input.name)}
                    <span> ({input.value})</span>
                  </p>
                  <p className={styles.singleItemPrice}>
                    {(input.value * input.pricePerUnit).toFixed(2)} &#x20AC;
                  </p>
                </div>
              </>
            );
          }

          return <></>;
        })}
        <hr className={styles.widgetDivider} />
        <div className={styles.widgetTotalPrice}>
          <p>Viso / mėn.</p>
          <p>{getTotalPrice(allInputs)} &#x20AC;</p>
        </div>
        <form className={styles.widgetForm} onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            required
            placeholder="El. pašto adresas"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="phone"
            name="phone"
            required
            placeholder="Telefono numeris"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.RECAPTCHA_KEY || RECAPTCHA_KEY}
            onChange={handleRecaptchaChange}
            theme="dark"
            className={styles.recaptcha}
          />
          <div className={styles.recaptchaMessage}>
            <p>Patvirtinkite reCAPTCHA</p>
          </div>
          <button type="submit">
            {isLoading ? <LoadingSpinner /> : "Gauti pasiūlymą"}
          </button>
        </form>
        {isSuccess && (
          <div className={styles.informationMessage}>
            <img
              src={SuccessMark}
              alt="Success mark icon"
              className={styles.checkmarkIcon}
            />
            <p>Jūsų užklausa buvo išsiųsta!</p>
          </div>
        )}
        {isFormEmpty && (
          <div className={styles.informationMessage}>
            <img
              src={WarningSign}
              alt="Warning sign icon"
              className={styles.checkmarkIcon}
            />
            <p>Pridėkite pageidaujamas paslaugas</p>
          </div>
        )}
        {isError && (
          <div className={styles.informationMessage}>
            <img
              src={ExclamationMark}
              alt="Exclamation mark icon"
              className={styles.checkmarkIcon}
            />
            <p>
              Įvyko klaida! Noredami pateikti užklausą kreipktiės email:
              info@profinanca.lt
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalculcatorWidget;
