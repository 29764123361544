import { WidgetInput } from "../../constants/widgetInputs";

export const getTotalPrice = (widgetInput: WidgetInput[]): string => {
  const result = widgetInput
    .map((widget) => widget.value * widget.pricePerUnit)
    .reduce((partialSum, a) => partialSum + a, 0)
    .toFixed(2);

  return result;
};

export const getLabelText = (label: string, name?: string): string => {
  if (label === "Taip" && name === "checkboxYes") {
    return "Periodinės ataskaitos";
  }

  if (label === "Prekybinė" && name === "activityCheckboxYes") {
    return "Prekybinė veikla";
  }

  if (label === "Paslaugų" && name === "activityCheckboxNo") {
    return "Paslaugų veikla";
  }

  if (label === "Darbuotojai") {
    return "Darbuotojų atlyginimo skaičiavimas";
  }

  if (label === "Taip" && name === "vatCheckboxYes") {
    return "PVM mokėtojas";
  }

  return label;
};

export const isSelectedOptionsEmpty = (inputData: WidgetInput[]): boolean => {
  const checkboxIputs = inputData.filter((input) => input.type === "checkbox");

  const numberInputs = inputData.filter((input) => input.type === "text");

  if (numberInputs.some((input) => input.value > 0)) {
    return true;
  }

  if (checkboxIputs.find((input) => input.label === "Taip")?.value === 1) {
    return true;
  }

  if (
    checkboxIputs.find((input) => input.name === "vatCheckboxYes")?.value === 1
  ) {
    return true;
  }

  if (checkboxIputs.find((input) => input.label === "Ne")?.value === 1) {
    return false;
  }

  return true;
};

export const getFormattedMessage = (widgetInput: WidgetInput[]): string => {
  const filteredInputs = widgetInput
    .filter((input) => input.value >= 1)
    .filter((input) => input.label !== "Ne");

  const messages = filteredInputs.map(
    (input) =>
      `${getLabelText(input.label, input.name)}: ${(
        input.value * input.pricePerUnit
      ).toFixed(2)}`
  );

  return messages.join(", ");
};
