import React, { ChangeEvent } from "react";
import styles from "./Input.module.scss";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import CheckmarkIcon from "../../asset/checkmark.svg";
import InfoCircle from "../../asset/info-circle.svg";
import { ReactSVG } from "react-svg";

export type InputType = "text" | "checkbox";

type Props = {
  type: InputType;
  label: string;
  pricePerUnit: string | number;
  value: string | number;
  helperText?: string;
  onIncrement?: (label: string) => void;
  onDecrement?: (label: string) => void;
  onCheckboxChange?: (label: string) => void;
  onNumberChange?: (event: ChangeEvent<any>, label: string) => void;
};

const Input = ({
  type,
  label,
  pricePerUnit,
  helperText,
  value,
  onIncrement,
  onDecrement,
  onCheckboxChange,
  onNumberChange,
}: Props): JSX.Element => {
  if (type === "text" && onIncrement && onDecrement && onNumberChange) {
    return (
      <div className={styles.inputNumberContainer}>
        <div className={styles.inputLabelBox}>
          <div>
            <p>
              {label}
              {helperText && (
                <span
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={helperText}
                  data-tooltip-place="top"
                >
                  <ReactSVG
                    src={InfoCircle}
                    className={styles.infoCircleIcon}
                  />
                </span>
              )}
            </p>
            <Tooltip id="my-tooltip" noArrow className={styles.tooltip} />
          </div>
        </div>
        <div className={styles.inputInputBox}>
          <div className={styles.inputInputContainer}>
            {
              <button
                className={styles.decrementButton}
                onClick={() => onDecrement(label)}
              >
                -
              </button>
            }
            <input
              type="text"
              onChange={(e) => onNumberChange(e, label)}
              value={value}
            ></input>
            {<button onClick={() => onIncrement(label)}>+</button>}
          </div>
          {pricePerUnit && <p>{pricePerUnit} &#x20AC;</p>}
        </div>
      </div>
    );
  }

  if (type === "checkbox" && onCheckboxChange) {
    return (
      <div className={styles.checkbox} onClick={() => onCheckboxChange(label)}>
        <div
          className={`${styles.checkboxIcon} ${value === 1 ? "checked" : ""}`}
        >
          {value === 1 && (
            <img
              src={CheckmarkIcon}
              alt="Checkmark icon"
              className={styles.checkmarkIcon}
            />
          )}
        </div>
        <div className={styles.checkboxLabel}>
          {label}
          {pricePerUnit > 0 && (
            <span className={styles.checkboxSpan}>{pricePerUnit} &#x20AC;</span>
          )}
        </div>
      </div>
    );
  }

  return <></>;
};

export default Input;
